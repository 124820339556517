// No services yet! (we might want to at this stage already)
import {render, CustomElement, defineElement, state, Host} from "@cerxes/csx";
import bootstrapStyle from "./bootstrap.pcss";


// A loader component to display when loading to other parts of our app
@defineElement("bootstrap-loader")
class BootstrapLoader extends CustomElement{
    @state()
    loaded = false;
    #animateOutDuration = 250;

    async start(){
        await Promise.all([
            import("./main.js").then(async (m)=>{
                console.log("App-Module loaded");
                await m.startApp();
            })
        ]);
        console.log("App loaded");
        this.loaded = true;
        this.update();

        console.log("End animation");
        await new Promise((resolve)=>setTimeout(()=>resolve(), this.#animateOutDuration));
        document.body.removeChild(this); // remove self!
    }

    render(){
        let dots = [100,200,300]

        return <Host
            style={
                this.loaded
                    ? {
                        animation: `slideOut ${this.#animateOutDuration}ms forwards`
                    }
                    : {
                    }
            }
        >
            <style>
                {bootstrapStyle}
            </style>
            <div className={"bootstraploader__box"}>
                <span className={"bootstraploader__loading-text"}>
                    Loading
                    {dots.map(delay=>(
                        <span
                            className={{
                            'bootstraploader__loading-dot': true,
                            'bootstraploader__loading-dot--loading': !this.loaded,
                        }}
                            style={{
                                animationDelay: `${delay}ms`
                            }}
                        >
                            .
                        </span>
                    ))}
                </span>
            </div>
        </Host>

    }

    connectedCallback() {
        super.connectedCallback();

        this.start();
    }
}


// IIFE
(async function(){
    let loader = render(
        <BootstrapLoader />
    );
    document.body.appendChild(loader);// we might need more style here?
})()
